import React from "react";

import Layout from "../../../components/Layout/Layout";

const Article09122020 = () => {
  return (
    <Layout>
      <div className="article">
        <div className="article__ribbon">
          <div className="grid-container">
            <h1 className="title">
              {" "}
              S&T AG COMMUNICATES DETAILS OF VISION 2030
            </h1>
            <p>
              Technology group S&T (ISIN AT0000A0E9W5, WKN A0X9EJ, SANT) today
              held its virtual Capital Market Day 2020 and provided more details
              of Vision 2030.
            </p>
            <p>
              In the first part of the presentation, the group’s experts were
              joined by their long-standing industry partners Microsoft and
              Intel for a presentation of topics including edge computing,
              artificial intelligence and communications technologies in the
              field of the IoT as well as the SUSiEtec IoT software framework.
            </p>
            <p>
              The technology part was followed by presentations of our customers
              from different markets and countries, that provided excellent
              insights into the areas in which S&T products are used and how
              cooperation with S&T works. The medical engineering company
              Getinge/Maquet, for instance, gave an account of the support it
              received from S&T in a year in which demand was high. The
              contributions from Infineon and Hauser focused on their
              experiences with S&T/Kontron’s IoT software. Representatives of
              NRT (Network Rail Telecoms (UK)) and SNCF (Société nationale des
              chemins de fer français (FR)) explained the complex requirements
              of communication with and between trains and highlighted the
              expertise of S&T in the field of train control and train
              communications along with the benefits they have reaped from the
              many years of partnership with S&T.
            </p>
            <p>
              In the course of a finance update Richard Neuwirth, CFO, spoke
              about initiatives taken to contribute to a further increase of the
              net result and cash flow, and confirmed that the group will
              continue to follow the path towards greater transparency and
              further reduction in complexity.
            </p>
            <p>
              Hannes Niederhauser, CEO of the S&T Group, followed by focusing on
              the pillars of S&T’s Vision 2030. S&T is driving digitization
              developments forward which promise excellent growth potential.
              With its smart product portfolio S&T is ideally placed and is
              responding with constant transformation in order to ensure
              continuing profitable growth. This organic growth is supported by
              a several times proven strategy of synergetic acquisitions. The
              concern, which already enjoys an excellent reputation with its
              customers, aims to raise its brand profile still more in future in
              order to broaden its investor basis. Hannes Niederhauser: “When I
              travel to work I see products with S&T/Kontron technology at every
              turn. Digitization is progressing rapidly and the range of
              potential products and fields of application is increasing all the
              time. With our technology and expertise we are ideally equipped to
              successfully exploit this environment to our advantage and to
              continue our growth strategy. Completion of our transformation
              from an IT service provider to a specialist IoT technology concern
              with more software and SLA contracts means that we will be able to
              significantly increase our gross and profit margins in the future.
              And we are also very well on track in the short term: despite the
              lockdowns in October and November, our business has developed
              positively and we will exceed our increased guidance for 2020.”
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Article09122020;
